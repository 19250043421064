/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import React, { useState, useEffect, useRef } from 'react';
import _isEqual from 'lodash/isEqual';
import { format as formatDate } from 'date-fns';
import axios from 'cccisd-axios';
import Loader from 'cccisd-loader';
import SubmitButton from 'cccisd-click-button';
import {
    Formik,
    Form,
    Field,
    FieldArray,
    CccisdCheckboxInput,
    CccisdTextarea,
    CccisdToggle,
    CccisdInput,
    CccisdSelect,
} from 'cccisd-formik';
import Modal from 'cccisd-modal';
import Tooltip from 'cccisd-tooltip';
import notification from 'cccisd-notification';
import IconTooltip from 'cccisd-icons/question4';
import IconDelete from 'cccisd-icons/bin';
import IconCheck from 'cccisd-icons/checkmark-circle';
import IconWarn from 'cccisd-icons/warning';
import IconCopy from 'cccisd-icons/clipboard';
import Accordian from '../../../components/Accordion';
import { revLanguages } from '../VideoSettings';
import style from './style.css';
import styleVideoSettings from '../VideoSettings/style.css';

const Boilerplate = window.cccisd && window.cccisd.boilerplate;
const Fortress = window.cccisd && window.cccisd.fortress;

export function useVideoData({ videoId }) {
    const [videoData, setVideoData] = useState();
    useEffect(() => {
        getVideoData();
    }, []);

    async function getVideoData() {
        const res = await axios.get(Boilerplate.route('api.producer.video.show', { video: videoId }));
        if (res.data) {
            setVideoData(res.data.data);
        }
    }

    return {
        videoData,
        refreshVideoData: () => getVideoData(),
    };
}

function CaptionSettings({ row }) {
    const { videoData, refreshVideoData } = useVideoData({ videoId: row['pawn.pawnId'] });

    if (!videoData) {
        return <Loader loading />;
    }

    const latestVersion = videoData.versions.find(v => v.id === videoData.latestVersion.id);
    const orders = latestVersion ? latestVersion.rev_orders : [];

    return (
        <>
            <Accordian
                title="Rev Orders"
                startOpen
                type="rev"
                footer={
                    <NewRevOrder
                        latestVersion={videoData.latestVersion}
                        row={row}
                        refreshVideoData={refreshVideoData}
                    />
                }
            >
                {orders.length === 0 && (
                    <strong style={{ color: 'lightgrey' }}>{`No Rev Orders submitted. ${
                        videoData.versions.length === 0
                            ? 'A video must be uploaded, and transcoding must be complete, before submitting.'
                            : ''
                    }`}</strong>
                )}
                {orders.map(order => {
                    return (
                        <RevOrderDetails
                            {...order}
                            version={videoData.latestVersion.version_number}
                            refreshVideoData={refreshVideoData}
                        />
                    );
                })}
            </Accordian>
            <TrackList videoId={videoData.id} captions={videoData.captions} refreshVideoData={refreshVideoData} />
        </>
    );
}

function TrackList({ videoId, captions = [], refreshVideoData }) {
    const [isLoading, setIsLoading] = useState(false);
    const validate = ({ tracks }) => {
        const errors = {};

        tracks.forEach((t, i) => {
            if (!t.label) {
                errors[`tracks[${i}].label`] = 'Label is required';
            }
            if (!t.language) {
                errors[`tracks[${i}].language`] = 'Language selection is required';
            }
            if (!t.content) {
                errors[`tracks[${i}].content`] = 'WebVTT content required.';
            }
        });

        return errors;
    };
    const handleSubmission = async ({ tracks }) => {
        setIsLoading(true);
        const tracksToStore = tracks
            .filter(t => !t.id)
            .map(t => {
                return () => axios.post(Boilerplate.route('api.producer.captions.store', { video: videoId }), { ...t });
            });
        const tracksToUpdate = tracks
            .filter(t => captions.some(c => t.id === c.id))
            .map(t => {
                return () =>
                    axios.put(
                        Boilerplate.route('api.producer.captions.update', {
                            video: videoId,
                            caption: t.id,
                        }),
                        { ...t }
                    );
            });
        const tracksToDelete = captions
            .filter(c => !tracks.some(t => c.id === t.id))
            .map(t => {
                return () =>
                    axios.delete(
                        Boilerplate.route('api.producer.captions.destroy', {
                            video: videoId,
                            caption: t.id,
                        }),
                        { ...t }
                    );
            });

        try {
            Promise.all([...tracksToStore, ...tracksToUpdate, ...tracksToDelete].map(t => t())).then(() => {
                notification({ type: 'success', message: 'Tracks saved successfuly.' });
                refreshVideoData();
            });
        } catch (e) {
            console.error({ e });
            notification({ type: 'danger', message: 'Unable to save tracks.' });
        } finally {
            setIsLoading(false);
        }
    };
    return (
        <Formik initialValues={{ tracks: captions }} onSubmit={handleSubmission} validate={validate}>
            {({ values, handleSubmit }) => {
                return (
                    <Form>
                        <FieldArray
                            name="tracks"
                            render={arr => (
                                <Accordian
                                    title="Tracks"
                                    type="tracks"
                                    footer={
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    if (values.tracks.length === 0) {
                                                        arr.push({
                                                            language: 'en',
                                                            label: 'English',
                                                            enabled: true,
                                                            content: '',
                                                        });
                                                    } else {
                                                        arr.push({
                                                            language: '',
                                                            label: '',
                                                            enabled: true,
                                                            content: '',
                                                            startOpen: true,
                                                        });
                                                    }
                                                }}
                                            >
                                                + Add Text Track
                                            </button>
                                            <SubmitButton
                                                title="Submit"
                                                className="btn btn-primary"
                                                onClick={handleSubmit}
                                                isLoading={isLoading}
                                                isConfirm={captions.some(
                                                    c => c.from_rev && !values.tracks.some(t => t.id === c.id)
                                                )}
                                                confirmationMessage={
                                                    <div className="alert alert-danger">
                                                        <p style={{ fontSize: '1em' }}>
                                                            <IconWarn spaceRight />
                                                            <strong>
                                                                Warning: You are about to delete one or more tracks
                                                                created from a Rev order. This action is unrecoverable.
                                                            </strong>
                                                        </p>
                                                        <br />
                                                        <p style={{ fontSize: '0.8em' }}>
                                                            However, you can always recreate a track with the WebVTT
                                                            text provided by a completed order.
                                                        </p>
                                                        <br />
                                                        <p style={{ fontSize: '0.8em' }}>Do you want to continue?</p>
                                                    </div>
                                                }
                                                isDisabled={_isEqual(captions, values.tracks)}
                                            />
                                        </div>
                                    }
                                >
                                    {values.tracks.length === 0 && (
                                        <strong style={{ color: 'lightgrey' }}>No tracks added</strong>
                                    )}
                                    {values.tracks.map((track, i) => (
                                        <Track track={track} index={i} arr={arr} tracks={values.tracks} />
                                    ))}
                                </Accordian>
                            )}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
}

function Track({ track, index, arr, tracks }) {
    const isEnableFieldDisabled = tracks.some(
        (t, i) =>
            !track.language ||
            (track.language &&
                index !== i &&
                track.language === t.language &&
                (t.enabled === true || t.enabled === '1' || t.enabled === 1))
    );

    let enableFieldMessage = null;

    if (isEnableFieldDisabled) {
        if (!track.language) {
            enableFieldMessage = 'This track can not be enabled until a language has been selected.';
        } else {
            enableFieldMessage = 'This track can not be enabled until another track of the same language is disbaled.';
        }
    }

    return (
        <Accordian
            title={
                <>
                    <label style={{ display: 'inline-flex', alignItems: 'center' }}>
                        <span style={{ width: '2.0rem', marginRight: '0.5rem' }} className="text-success">
                            {track.enabled ? <IconCheck /> : <span>&nbsp;</span>}
                        </span>
                        <span className="badge badge-default" style={{ marginRight: '1rem' }}>
                            {track.source}
                        </span>
                        {track.label || (
                            <span style={{ color: 'lightgrey' }}>
                                {track.content == null ? 'Generating...' : 'Track Label'}
                            </span>
                        )}
                        {track.from_aws ? <span>&nbsp;(version {track.version.version_string})</span> : null}
                    </label>
                    <button
                        className="btn btn-xs btn-danger"
                        style={{ float: 'right' }}
                        type="button"
                        onClick={() => arr.remove(index)}
                    >
                        <IconDelete />
                    </button>
                </>
            }
            context="default"
            startOpen={track.startOpen}
        >
            <Field name={`tracks[${index}].label`} component={CccisdInput} label="Track Label" />
            <Field
                name={`tracks[${index}].language`}
                component={CccisdSelect}
                options={[
                    { label: '-- Choose a Language --', value: '' },
                    ...Object.entries(revLanguages)
                        .map(l => {
                            return { label: l[1], value: l[0] };
                        })
                        .filter(
                            l => l.value === track.language || !tracks.some(t => l.value === t.language && t.enabled)
                        ),
                ]}
                label="Language"
            />
            {/* Type disabled for now. May reenabled later. */}
            {/* <Field
                name={`tracks[${index}].type`}
                component={CccisdSelect}
                options={['captions', 'subtitles'].map(o => {
                    return { label: _capitalize(o), value: o };
                })}
                label="Type"
            /> */}
            <Field
                name={`tracks[${index}].enabled`}
                component={CccisdToggle}
                label={
                    isEnableFieldDisabled ? (
                        <Tooltip title={enableFieldMessage}>
                            <span style={{ color: '#ccc' }}>Enable</span>
                        </Tooltip>
                    ) : (
                        'Enable'
                    )
                }
                disabled={isEnableFieldDisabled}
            />
            <Field
                name={`tracks[${index}].content`}
                component={CccisdTextarea}
                label="WebVTT"
                rows="10"
                disabled={track.from_rev}
            />
            {track.order && (
                <div style={{ color: '#ddd' }}>
                    <strong>
                        <em>{`Created by Rev Order #${track.order.order_number}`}</em>
                    </strong>
                </div>
            )}
        </Accordian>
    );
}

function RevOrderDetails({
    captions,
    completed_at,
    created_at,
    glossary,
    id,
    languages,
    order_number,
    ordered_at,
    ordered_by,
    price_actual,
    price_estimate,
    raw_request,
    raw_response,
    rush,
    speakers,
    status,
    time_actual,
    time_estimate,
    updated_at,
    version_id,
    refreshVideoData,
    version,
}) {
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);
    const orderedAt = formatDate(new Date(ordered_at), "'on' PP 'at' p");
    const completedAt = formatDate(new Date(completed_at), "'on' PP 'at' p");

    const Details = () => (
        <div>
            <div>{`Placed ${orderedAt} by ${
                ordered_by.user.full_name
                    ? `${ordered_by.user.full_name} (${ordered_by.user.username})`
                    : ordered_by.user.username
            }`}</div>
            {status !== 'Cancelled' && (
                <>
                    <div>{`Media Verison: ${version.toString().padStart(3, '0')}`}</div>
                    <div>{`Cost: $${price_actual || price_estimate}`}</div>
                </>
            )}
        </div>
    );

    async function refreshOrder() {
        setIsRefreshing(true);
        try {
            await axios.get(Boilerplate.route('api.rev.order.refresh', { order: id }));
        } catch (e) {
            console.error(e);
            setIsRefreshing(false);
        } finally {
            await refreshVideoData()
                .then(() => setIsRefreshing(false))
                .then(() => setIsCancelling(false));
        }
    }

    async function cancelOrder() {
        setIsCancelling(true);
        try {
            const res = await axios.get(Boilerplate.route('api.rev.order.cancel', { order: id }));
            if (res.data.status === 'error') {
                notification({ type: 'danger', message: 'Order can no longer be cancelled.' });
                setIsCancelling(false);
            } else {
                notification('Order cancelled successfully.');
            }
        } catch (e) {
            console.error({ e });
            setIsCancelling(false);
        } finally {
            await refreshOrder();
        }
    }

    if (status === 'Order Placed') {
        return (
            <Accordian
                footer={
                    <div style={{ display: 'flex', gap: '1em' }}>
                        <button type="button" className="btn btn-danger" onClick={cancelOrder} disabled={isCancelling}>
                            {isCancelling ? 'Cancelling...' : 'Cancel Order'}
                        </button>
                        <button
                            type="button"
                            className="btn btn-default"
                            onClick={refreshOrder}
                            disabled={isRefreshing || isCancelling}
                        >
                            {isRefreshing && !isCancelling ? 'Checking...' : 'Check Now'}
                        </button>
                    </div>
                }
                context="info"
                title={
                    <span>
                        {`Order #${order_number}`}
                        <span className="badge badge-info" style={{ float: 'right' }}>
                            Received
                        </span>
                    </span>
                }
                type="received"
            >
                <div>Order received by Rev</div>
                <Details />
            </Accordian>
        );
    }

    if (status === 'Cancelled') {
        return (
            <Accordian
                context="default"
                title={
                    <span>
                        <span>{`Order #${order_number}`}</span>
                        <span className="badge badge-default" style={{ float: 'right' }}>
                            Cancelled
                        </span>
                    </span>
                }
                type="cancelled"
            >
                <div>Order cancelled</div>
                <Details />
            </Accordian>
        );
    }

    if (status === 'Complete') {
        return (
            <Accordian
                context="success"
                footer={
                    <button type="button" className="btn btn-default" onClick={refreshOrder} disabled={isRefreshing}>
                        {isRefreshing ? 'Refreshing...' : 'Refresh Order'}
                    </button>
                }
                title={
                    <span>
                        <span>{`Order #${order_number}`}</span>
                        <span className="badge badge-success" style={{ float: 'right' }}>
                            Complete
                        </span>
                    </span>
                }
                type="completed"
            >
                <div>{`Completed ${completedAt}`}</div>
                <Details />
                <br />
                <label>Captions</label>
                {captions.map(c => {
                    return (
                        <div className={style.accordianComplete}>
                            <Accordian
                                context="default"
                                title={<label style={{ margin: 0 }}>{c.label || revLanguages[c.language]}</label>}
                            >
                                <div>Transcript</div>
                                <div style={{ position: 'relative' }}>
                                    <pre style={{ whiteSpace: 'pre-wrap', height: '15em' }}>
                                        <br />
                                        {c.transcript}
                                    </pre>
                                    <button
                                        type="button"
                                        className="btn btn-default"
                                        style={{ position: 'absolute', top: '0', right: '0' }}
                                        onClick={() =>
                                            navigator.clipboard
                                                .writeText(c.transcript)
                                                .then(() => notification('Transcript Copied!'))
                                        }
                                    >
                                        <IconCopy spaceRight />
                                        Copy
                                    </button>
                                </div>
                                <div>WebVTT</div>
                                <div style={{ position: 'relative' }}>
                                    <pre style={{ whiteSpace: 'pre-wrap', height: '15em' }}>
                                        <br />
                                        {c.content}
                                    </pre>
                                    <button
                                        type="button"
                                        className="btn btn-default"
                                        style={{ position: 'absolute', top: '0', right: '0' }}
                                        onClick={() =>
                                            navigator.clipboard
                                                .writeText(c.content)
                                                .then(() => notification('WebVTT Copied!'))
                                        }
                                    >
                                        <IconCopy spaceRight />
                                        Copy
                                    </button>
                                </div>
                            </Accordian>
                        </div>
                    );
                })}
                <br />
                <label>Additional Info</label>
                <div className={style.accordianComplete}>
                    <Accordian context="info" title={<label style={{ margin: 0 }}>Rev Comments</label>}>
                        {raw_response.comments.map((c, i) => {
                            const timestamp = formatDate(new Date(c.timestamp), "'on' PP 'at' p");
                            return (
                                <>
                                    <div>
                                        <strong>
                                            {c.by || 'Rev'} {timestamp}
                                        </strong>
                                        <p>{c.text}</p>
                                    </div>
                                    {i !== raw_response.comments.length - 1 && <hr />}
                                </>
                            );
                        })}
                    </Accordian>
                </div>
            </Accordian>
        );
    }

    return (
        <Accordian
            context="warning"
            footer={
                <button type="button" className="btn btn-default" onClick={refreshOrder} disabled={isRefreshing}>
                    {isRefreshing ? 'Checking...' : 'Check Now'}
                </button>
            }
            title={
                <span>
                    <span>{`Order #${order_number}`}</span>
                    <span className="badge badge-warning" style={{ float: 'right' }}>
                        {status}
                    </span>
                </span>
            }
            type="in progress"
        >
            <Details />
        </Accordian>
    );
}

function NewRevOrder(props) {
    const modalRef = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const langKeys = Object.keys(revLanguages);

    const handleSubmission = async values => {
        setIsLoading(true);
        try {
            const res = await axios.post(
                Boilerplate.route('api.producer.captions.order', {
                    version: props.latestVersion.id,
                }),
                {
                    ...values,
                    languages: Object.keys(values.languages).filter(l => l !== 'en'),
                    speakers: values.speakers ? values.speakers.split('\n') : [],
                    glossary: values.glossary ? values.glossary.split('\n') : [],
                }
            );
            if (res.data.errors) {
                throw new Error(res.data.errors.error);
            }
            if (res.data.data) {
                notification('Your order has been submitted.');
                modalRef.current.close();
            }
        } catch (e) {
            notification({
                type: 'danger',
                message: e.message,
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            trigger={
                <button className="btn btn-primary" type="button" disabled={!props.latestVersion}>
                    + New Order
                </button>
            }
            title="New Order"
            ref={modalRef}
            afterClose={() => props.refreshVideoData()}
            render={({ closeModal }) => {
                return (
                    <Formik
                        initialValues={{ languages: { en: true }, speakers: '', glossary: '' }}
                        onSubmit={handleSubmission}
                    >
                        {({ values, handleSubmit }) => {
                            return (
                                <Form>
                                    <div className={styleVideoSettings.languages}>
                                        <label>Additional Langauges: </label>
                                        {langKeys.map((lan, i) => {
                                            if (i % 3 === 0) {
                                                return (
                                                    <div className={styleVideoSettings.languageRow} key={i}>
                                                        {[0, 1, 2].map((colNum, id) => {
                                                            const langKey = langKeys[i + colNum];
                                                            return (
                                                                langKey && (
                                                                    <div
                                                                        className={
                                                                            styleVideoSettings[`col${colNum + 1}`]
                                                                        }
                                                                        key={id}
                                                                    >
                                                                        <Field
                                                                            name={`languages.${langKey}`}
                                                                            component={CccisdCheckboxInput}
                                                                            label={`${revLanguages[langKey]}`}
                                                                            labelNotBold
                                                                            disabled={langKey === 'en'}
                                                                        />
                                                                    </div>
                                                                )
                                                            );
                                                        })}
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>
                                    <Field
                                        name="speakers"
                                        component={CccisdTextarea}
                                        label={
                                            <span>
                                                Speakers
                                                <Tooltip title="Optional. One per line.">
                                                    <IconTooltip spaceLeft />
                                                </Tooltip>
                                            </span>
                                        }
                                    />
                                    <Field
                                        name="glossary"
                                        component={CccisdTextarea}
                                        label={
                                            <span>
                                                Glossary Terms
                                                <Tooltip title="Optional. One per line.">
                                                    <IconTooltip spaceLeft />
                                                </Tooltip>
                                            </span>
                                        }
                                    />
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <button className="btn btn-link" type="button" onClick={closeModal}>
                                            Cancel
                                        </button>
                                        <Modal
                                            trigger={
                                                <button className="btn btn-primary" type="button">
                                                    Review Order
                                                </button>
                                            }
                                            title="Review Order"
                                            render={({ closeModal }) => (
                                                <ReviewRevOrder
                                                    handleSubmit={handleSubmit}
                                                    latestVersion={props.latestVersion}
                                                    values={values}
                                                    closeModal={closeModal}
                                                    row={props.row}
                                                    isLoading={isLoading}
                                                />
                                            )}
                                        />
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                );
            }}
        />
    );
}

function ReviewRevOrder(props) {
    const languages = Object.entries(props.values.languages)
        .filter(l => l[1] && l[0] !== 'en')
        .map(l => l[0]);
    const [orderDetails, setOrderDetails] = useState({ ...props.values, languages });

    useEffect(() => {
        async function getEstimate() {
            const res = await axios.post(
                Boilerplate.route('api.producer.captions.estimate', {
                    version: props.latestVersion.id,
                }),
                { ...props.values, languages }
            );
            setOrderDetails(prev => {
                return { ...prev, ...res.data.data };
            });
        }
        getEstimate();
    }, []);
    return (
        <>
            <table className={`table ${style.revOrderReview}`}>
                <colgroup>
                    <col />
                    <col />
                </colgroup>
                <tr>
                    <td>Video:</td>
                    <td>{props.row['fields.label']}</td>
                </tr>
                <tr>
                    <td>Version:</td>
                    <td>{props.latestVersion.version_number.toString().padStart(3, '0')}</td>
                </tr>
                <tr>
                    <td>Lesson:</td>
                    <td>{props.row['parentGroup.site.group.label']}</td>
                </tr>
                <tr>
                    <td>Module:</td>
                    <td>{props.row['ancestorGroups.groupingUnit.group.label']}</td>
                </tr>
                <tr style={{ borderBottom: '2em solid transparent' }}>
                    <td>Project:</td>
                    <td>{props.row['ancestorGroups.organization.group.label']}</td>
                </tr>
                <tr style={{ borderTop: '2em solid transparent' }}>
                    <td>Additional Languages:</td>
                    <td>
                        {orderDetails.languages.length > 0
                            ? orderDetails.languages.map(l => <div>{revLanguages[l]}</div>)
                            : '-'}
                    </td>
                </tr>
                <tr>
                    <td>Speakers:</td>
                    <td>{orderDetails.speakers || '-'}</td>
                </tr>
                <tr style={{ borderBottom: '2em solid transparent' }}>
                    <td>Glossary Terms:</td>
                    <td>{orderDetails.glossary || '-'}</td>
                </tr>
                <tr style={{ borderTop: '2em solid transparent' }}>
                    <td>Estimated Cost:</td>
                    <td>{` $${orderDetails.costEstimate}`}</td>
                </tr>
                <tr>
                    <td>Estimated Time:</td>
                    <td>{` ${orderDetails.timeEstimateAll} hours`}</td>
                </tr>
                <tr>
                    <td>Placed by:</td>
                    <td>{Fortress.user.acting.user.full_name || Fortress.user.acting.user.username}</td>
                </tr>
            </table>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <button type="button" className="btn btn-link" onClick={props.closeModal}>
                    Cancel
                </button>
                <SubmitButton className="btn btn-primary" onClick={props.handleSubmit} isLoading={props.isLoading}>
                    Place Order
                </SubmitButton>
            </div>
        </>
    );
}

export default CaptionSettings;
